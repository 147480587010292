<template>
  <div class="carbon-sink-log">
    <div class="card-info">
      <div class="flex top">
        <img class="ava" :src="userInfo.avatar">
        <div class="name">
          <h2>{{ userInfo.name }}</h2>
          <p>{{ userInfo.mobile || '-' }}</p>
        </div>
        <img class="medal" src="@/assets/icon/carbon-sink-log-medal.png">
      </div>
      <div class="bottom">{{ userInfo.villageName }} {{ userInfo.buildingNo }}栋 {{ userInfo.unitNo }}单元 {{ userInfo.houseNumber }}室</div>
    </div>
    <div class="timeline">
      <div class="timeline-row">
        <div class="dot" />
        <h4>2016-10-13 14:00</h4>
        <div class="info-row">
          <div class="top">易腐垃圾</div>
          <div class="bottom">重量：45kg</div>
        </div>
        <div class="info-row">
          <div class="top">可回收垃圾</div>
          <div class="bottom">重量：45kg</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {}
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$http.get(`/village/household/${this.$store.state.token}`).then(res => {
        if (res.code === 0) {
          this.userInfo = res.data
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.carbon-sink-log {
  background: #F1F1F1;
  .card-info {
    height: 4.2rem;
    background: linear-gradient(360deg, #1BBF00 0%, #8AE760 100%);
    padding: 0 0.6rem;
    .top {
      height: 2.2rem;
      .ava {
        height: 1.6rem;
        width: 1.6rem;
        border-radius: .8rem;
        background-color: #CCC;
      }
      .name {
        flex: 1;
        padding-left: .2rem;
        h2 {
          font-size: .53rem;
          color: #FFF;
        }
        p {
          color: rgba(255, 255, 255, .6);
          font-size: .49rem;
        }
      }
      .medal {
        max-height: 1.6rem;
        max-width: 1rem;
      }
    }
    .bottom {
      height: 1.56rem;
      padding: 0.25rem;
      font-size: 0.4rem;
      color: rgba(255, 255, 255, .8);
      background-color: #FFC84F;
      font-weight: bolder;
      border-radius: .3rem;
    }
  }
  .timeline {
    padding: .4rem;
    .timeline-row {
      position: relative;
      padding: 0 .21rem;
      border-left: .01rem solid #999;
      .dot {
        position: absolute;
        left: -.14rem;
        top: 0;
        height: .28rem;
        width: .28rem;
        border-radius: .14rem;
        background-color: #999;
      }
      h4 {
        position: relative;
        top: -.1rem;
        left: .2rem;
        font-size: 0.37rem;
        color: #828282;
        margin: 0;
      }
      .info-row {
        height: 2.67rem;
        background-color: #FFF;
        border-radius: .4rem;
        color: #333;
        font-size: 0.53rem;
        font-weight: bold;
        padding: 0 .3rem;
        margin-bottom: .4rem;
        .top {
          line-height: 1.18rem;
          border-bottom: .01rem solid #F1F1F1;
        }
        .bottom {
          line-height: 1.48rem;
        }
      }
    }
  }
}
</style>